import React, {useEffect} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useDispatch} from "react-redux";
import {nullifyGeneralReportStore} from "../../../store/generalReports/actions/GeneralReportActions";
import {useHistory} from "react-router-dom";
import FormHeader from "../../Form/FormHeader";
import FormActionContainer from "../../Form/FormActionContainer";
import {MMReportButtons} from "./Helpers/reportHelpers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const GeneralReports = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];

        dispatch(setNavigationItems(nav));

        return function () {
            dispatch(nullifyGeneralReportStore());
        };
    }, []);

    const goToReport = (pathname: string) => {
        history.push({pathname});
    };
    return (
        <React.Fragment>
            <TopBar titleText="Reporting" />
            <div className="page-container pt-4">
                <FormHeader headerName={"Select Report Type"} />
                <FormActionContainer>
                    <div className="report-buttons">
                        {MMReportButtons.map((report, index) => {
                            return (
                                <div key={index} className="report-button">
                                    <MCButton
                                        size={ButtonSize.ExtraLarge}
                                        innerValue={report.reportType}
                                        onClick={() => goToReport(report.internalUrl)}
                                        colour={ButtonColourOptions.DarkBlue}
                                        className="w-100"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default GeneralReports;
