export const lightThemeBlue = "#4daec7";
export const lightThemeGreen = "#4ba988";
export const lightThemeOrange = "#c2824a";
export const lightThemeRed = "#c24e4a";
export const lightThemeWhite = "#eeeeee";

export const vibrantGreenPrimary = "#2A8A41";
export const vibrantGreenSecondary = "#6cd991";
export const vibrantRedPrimary = "#C70A0A";
export const vibrantRedSecondary = "#f57571";
export const vibrantOrangePrimary = "#d2823e";
export const vibrantOrangeSecondary = "#edb07b";
export const vibrantYellowPrimary = "#E0A309";
export const vibrantYellowSecondary = "#e0cc65";
export const vibrantBluePrimary = "#2fb6d0";
export const vibrantBlueSecondary = "#65d4eb";

export const redGradient = `linear-gradient(to right, ${vibrantRedPrimary}, ${vibrantRedSecondary})`;
export const yellowGradient = `linear-gradient(to right, ${vibrantYellowPrimary}, ${vibrantYellowSecondary})`;
export const blueGradient = `linear-gradient(to right, ${vibrantBluePrimary}, ${vibrantBlueSecondary})`;
export const greenGradient = `linear-gradient(to right, ${vibrantGreenPrimary}, ${vibrantGreenSecondary})`;
