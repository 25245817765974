import React, {useEffect, useState} from "react";
import FilterContainer from "../../../Filters/FilterContainer";
import StockManagementFilters from "./StockManagementFilters";
import {useDispatch, useSelector} from "react-redux";
import {DrugBatch, DrugBatchPageRequest, DrugBatchPageResponse} from "../../../../api/mm";
import {fetchPagedDrugBatches} from "../../../../store/drugBatchPaged/actions/DrugBatchPagedActions";
import {PulseTableWithServerPagination} from "pulse_table";
import {pagedRequestConfig} from "../../../Filters/helpers/filterHelpers";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {getRemainingStock} from "../Helpers/stockManagementListHelpers";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import moment, {Moment} from "moment";
import Tooltip from "../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../Icon/Icon";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import DeleteTableItemAction from "../../../Table/DeleteTableItemAction";
import {RootStore} from "../../../../store/Store";
import TableItemAction from "../../../Table/TableItemAction";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {deleteDrugBatchById} from "../../../../store/drugBatch/actions/DrugBatchActions";
import AdministerDrugAction from "../../DrugPackWithContents/Components/Actions/AdministerDrugAction";
import DisposeDrugAction from "../../DrugPackWithContents/Components/Actions/DisposeDrugAction";
import {getAllStaffMembers} from "../../../../store/staffList/actions/StaffListActions";

const StockManagementTable = (props: DrugBatchPageResponse) => {
    const dispatch = useDispatch();
    const [drugBatchPagedRequest, setDrugBatchPagedRequest] = useState<DrugBatchPageRequest>();
    const history = useHistory();
    const drugBatchStore = useSelector((state: RootStore) => state.drugBatch);
    const {searchQueries} = usePageUrl();

    useEffect(() => {
        dispatch(getAllStaffMembers());
    }, []);

    const onRequestChanged = (request: DrugBatchPageRequest) => {
        if (!drugBatchPagedRequest) {
            setDrugBatchPagedRequest(request);
            fetchData(request);
            return;
        }

        if (JSON.stringify(request) === JSON.stringify(drugBatchPagedRequest)) return;

        setDrugBatchPagedRequest(request);
        fetchData(request);
    };

    const viewActiveStockReport = () => {
        history.push({
            pathname: routeNames.viewActiveStock.path,
            search: window.location.search
        });
    };

    // Fetches Audits
    const fetchData = (request: DrugBatchPageRequest) => {
        dispatch(fetchPagedDrugBatches(request));
    };

    const editDrugBatch = (item: TableRow) => {
        const path = `${routeNames.stockManagementEdit.path}/${item.drug.id}`;
        history.push({pathname: path, search: searchQueries});
    };

    const auditDrugBatch = (item: TableRow) => {
        const path = `${routeNames.stockManagementAudit.path}/${item.drug.id}`;
        history.push({pathname: path, search: searchQueries});
    };

    const addStock = () => {
        const path = `${routeNames.stockManagementAdd.path}`;
        history.push({pathname: path, search: searchQueries});
    };

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <StockManagementFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <div className="row mt-5 ml-0 mr-0">
                <div className="col mb-4 d-flex justify-content-end pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Stock"}
                        onClick={addStock}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"View Active Stock Report"}
                        onClick={viewActiveStockReport}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </div>
            </div>
            <PulseTableWithServerPagination
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                totalResults={props.totalCount || 0}
                onPageChanged={(newPageNum) => {
                    if (!drugBatchPagedRequest) return;
                    fetchData({
                        ...drugBatchPagedRequest,
                        pageNum: newPageNum
                    });
                }}
                items={toTableRow(props)}
                headers={{
                    drugName: "Drug Name",
                    drugType: "Drug Type",
                    batchNumber: "Batch Number",
                    quantity: "Quantity",
                    activeStock: "Active Stock",
                    lowStockQty: "Low Stock Quantity",
                    expiryDate: "Expiry Date",
                    drug: "Actions"
                }}
                customRenderers={{
                    lowStockQty: (item: TableRow) => {
                        const lowStockWarning = getRemainingStock(item.drug) <= item.lowStockQty;
                        return (
                            <React.Fragment>
                                {lowStockWarning ? (
                                    <React.Fragment>{renderLowStockQty(item.drug)}</React.Fragment>
                                ) : (
                                    <p className="mb-0">{item.lowStockQty}</p>
                                )}
                            </React.Fragment>
                        );
                    },
                    expiryDate: (item: TableRow) => {
                        const {drug} = item;
                        const expiring = getDrugExpiry(drug.expiryDate, drug.name);
                        return (
                            <React.Fragment>
                                <p className="mb-0">
                                    <span className="mr-2">{item.expiryDate}</span>
                                    {expiring && (
                                        <React.Fragment>
                                            <Tooltip
                                                tooltipText={expiring.tooltipMessage}
                                                size={"md"}
                                                place={"right"}
                                                theme={"dark"}
                                            >
                                                <Icon
                                                    rootElement={"Span"}
                                                    icon={IconType.DrugExpiry}
                                                    size={"Medium"}
                                                    className={`cursor-pointer ${getExpiryIconClassName(
                                                        expiring
                                                    )}`}
                                                />
                                            </Tooltip>
                                        </React.Fragment>
                                    )}
                                </p>
                            </React.Fragment>
                        );
                    },
                    drug: (item: TableRow) => (
                        <React.Fragment>
                            <div className="row ml-0 mr-0">
                                <TableItemAction
                                    tooltipText={`Edit ${item.drugName}`}
                                    onClick={() => editDrugBatch(item)}
                                    icon={IconType.Edit}
                                />
                                <DeleteTableItemAction
                                    loading={drugBatchStore.loading}
                                    modalTitle={"Delete Drug"}
                                    modalMessage={`Are you sure you want to delete ${item.drugName}?`}
                                    onConfirm={async () => {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        const deleted: boolean = await dispatch(
                                            deleteDrugBatchById(item.drug.id)
                                        );
                                        if (!deleted) return false;
                                        if (!drugBatchPagedRequest) return false;

                                        fetchData(drugBatchPagedRequest);
                                        return true;
                                    }}
                                    itemName={item.drugName}
                                />
                                <TableItemAction
                                    wrapperClassName={"ml-2"}
                                    tooltipText={`Audit ${item.drugName}`}
                                    onClick={() => auditDrugBatch(item)}
                                    icon={IconType.Audit}
                                />
                            </div>
                            {getRemainingStock(item.drug) > 0 && (
                                <div className="row ml-0 mt-2 mr-0">
                                    <AdministerDrugAction
                                        batchInfo={item.drug}
                                        maxQty={getRemainingStock(item.drug)}
                                        nominalMovementId={""}
                                    />
                                    <DisposeDrugAction
                                        batchInfo={item.drug}
                                        maxQty={getRemainingStock(item.drug)}
                                        nominalMovementId={""}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no drugs matching these filters"} />
                }
            />
        </React.Fragment>
    );
};

export default StockManagementTable;

interface TableRow {
    drugName: string;
    drugType: string;
    batchNumber: string;
    quantity: number;
    activeStock: number;
    lowStockQty: number;
    expiryDate: string;
    drug: DrugBatch;
}

function toTableRow(resp: DrugBatchPageResponse): TableRow[] {
    return resp.results.map((batch) => {
        return {
            drugName: batch.name,
            drugType: getUiFriendlyText(batch.type),
            batchNumber: batch.batchNumber,
            quantity: batch.qty,
            activeStock: getRemainingStock(batch),
            lowStockQty: batch.lowStockQty,
            expiryDate: formatUnixToDDMMYYYY(batch.expiryDate),
            drug: batch
        };
    });
}

// eslint-disable-next-line no-shadow
export enum DrugExpiryType {
    Week = "Week",
    Month = "Month"
}

export interface DrugExpiry {
    expiring: boolean;
    expiryType: DrugExpiryType;
    tooltipMessage: string;
}

export function getDrugExpiry(expiryDate: number, drugName: string): DrugExpiry | undefined {
    const now = moment();
    const oneWeekInFuture = now.clone().add(1, "week");
    const oneMonthInFuture = now.clone().add(1, "month");
    const dayDiff = getDayDifference(expiryDate, now);

    if (+dayDiff === 0) {
        return {
            expiring: true,
            expiryType: DrugExpiryType.Week,
            tooltipMessage: `${drugName} is expiring today! Urgent action is needed`
        };
    }

    if (expiryDate < now.startOf("day").unix()) {
        return {
            expiring: true,
            expiryType: DrugExpiryType.Week,
            tooltipMessage: `${drugName} has expired. Urgent action is needed!`
        };
    }

    if (expiryDate > now.unix() && expiryDate < oneWeekInFuture.unix()) {
        return {
            expiring: true,
            expiryType: DrugExpiryType.Week,
            tooltipMessage: `${drugName} expires in ${dayDiff} days. Action is required soon!`
        };
    }

    if (expiryDate > now.unix() && expiryDate < oneMonthInFuture.unix()) {
        return {
            expiring: true,
            expiryType: DrugExpiryType.Month,
            tooltipMessage: `${drugName} expires in ${dayDiff} days. Action is required soon!`
        };
    }
}

export function getDayDifference(expiryDate: number, now: Moment) {
    return Math.ceil(moment.duration(moment.unix(expiryDate).diff(now)).asDays()).toFixed(0);
}

export function getExpiryIconClassName(expiry: DrugExpiry) {
    switch (expiry.expiryType) {
        case DrugExpiryType.Week:
            return "drug-expiry-week";
        case DrugExpiryType.Month:
            return "drug-expiry-month";
    }
}

export function renderLowStockQty(drugBatch: DrugBatch) {
    const activeStock = getRemainingStock(drugBatch);

    if (activeStock === 0) {
        return (
            <React.Fragment>
                <p className="mb-0">
                    <span className="float-left">{drugBatch.lowStockQty} </span>
                    <Tooltip
                        tooltipText={`${drugBatch.name} is out of stock. Action required!`}
                        size={"lg"}
                        place={"right"}
                        theme={"dark"}
                    >
                        <div className="low-stock-red low-stock-lg float-left" />
                    </Tooltip>
                </p>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <p className="mb-0">
                <span className="float-left">{drugBatch.lowStockQty} </span>
                <Tooltip
                    tooltipText={`${drugBatch.name} is running low on stock. Action required!`}
                    size={"lg"}
                    place={"right"}
                    theme={"dark"}
                >
                    <div className="low-stock-yellow low-stock-lg float-left" />
                </Tooltip>
            </p>
        </React.Fragment>
    );
}
