import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";

import DisposalReportTable from "./Components/DisposalReportTable";
import {WithServiceState} from "store-fetch-wrappers";
import {nullifyGeneralReportStore} from "../../../../../store/generalReports/actions/GeneralReportActions";
import {nullifyDisposalReportStore} from "../../../../../store/disposalReports/actions/DisposalReportsActions";
import TopBar from "../../../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(DisposalReportTable);

const DisposalReport = () => {
    const dispatch = useDispatch();
    const disposalReportStore = useSelector((state: RootStore) => state.disposalReports);

    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];

        dispatch(setNavigationItems(nav));
        return function () {
            dispatch(nullifyGeneralReportStore());
            dispatch(nullifyDisposalReportStore());
        };
    }, []);

    return (
        <React.Fragment>
            <TopBar titleText="Disposal Reports" />
            <div className="pt-4 page-container">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...disposalReportStore}
                />
            </div>
        </React.Fragment>
    );
};

export default DisposalReport;
