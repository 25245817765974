import React, {useEffect} from "react";
import AuditListTable from "./Components/AuditListTable";
import {WithServiceState} from "store-fetch-wrappers";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {getAuditLocations} from "../../../store/locationList/actions/LocationListActions";
import {useUserData} from "../../Hooks/useUserData";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getNavigationItemsForUser} from "./Helpers/auditListHelpers";
import {getAllStaffMembers} from "../../../store/staffList/actions/StaffListActions";
import useMcConfig from "../../Hooks/useMcConfig";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(AuditListTable);

const AuditList = () => {
    const user = useUserData();
    const dispatch = useDispatch();
    const pagedAuditStore = useSelector((state: RootStore) => state.auditListPaged);
    const {config} = useMcConfig();

    useEffect(() => {
        dispatch(getAuditLocations());
        dispatch(getAllStaffMembers());
    }, []);
    //
    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;

        dispatch(setNavigationItems(getNavigationItemsForUser(user, config)));
    }, [user, config]);

    return (
        <React.Fragment>
            <TopBar titleText="Audit List" />
            <div className="page-container mt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...pagedAuditStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AuditList;
