import React from "react";
import {DrugAuditPagedResponse, DrugAuditType, StaffLink} from "../../../../api/mm";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {IconType} from "../../../Icon/Icon";
import AuditListFilters from "./AuditListFilters";
import {StaffAccessLevel} from "../../../../api/staff";
import TableItemAction from "../../../Table/TableItemAction";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import FilterContainer from "../../../Filters/FilterContainer";
import DeleteTableItemAction from "../../../Table/DeleteTableItemAction";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useAuditListTable} from "../Hooks/useAuditListTable";
import {useControlDrugAuditDownload} from "../Hooks/useControlDrugAuditDownload";

const AuditListTable = (props: DrugAuditPagedResponse) => {
    const {
        onRequestChanged,
        auditStore,
        viewAudit,
        viewHistoricAudits,
        newAudit,
        editAudit,
        deleteAudit,
        pagedRequest
    } = useAuditListTable();

    const {state, downloadControlDrugAudits} = useControlDrugAuditDownload();

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <AuditListFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <div className="row mt-5 ml-0 mr-0">
                <div className="col mb-4 d-flex justify-content-end pr-0">
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        {pagedRequest &&
                            pagedRequest.type === DrugAuditType.ControlledDrugAudit && (
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={
                                        state.loading
                                            ? "Downloading CSV"
                                            : "Download Control Drug Audit CSV"
                                    }
                                    onClick={() => downloadControlDrugAudits(pagedRequest)}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                    disabled={state.loading}
                                />
                            )}
                    </AuthAmI>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"New Audit"}
                        onClick={newAudit}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </div>
            </div>
            <PulseTable
                items={sortPagedResponseToTableRow(props)}
                headers={{
                    type: "Audit Type",
                    dateCreatedDetails: "Date Created Details",
                    dateModifiedDetails: "Date Modified Details",
                    reportingManager: "Reporting Manager",
                    locationName: "Location",
                    comments: "Comments",
                    actions: "Actions"
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no audits matching the filter criteria"} />
                }
                customRenderers={{
                    // eslint-disable-next-line react/display-name
                    dateCreatedDetails: (item: TableRow) => (
                        <React.Fragment>{item.dateCreatedDetails}</React.Fragment>
                    ),
                    // eslint-disable-next-line react/display-name
                    dateModifiedDetails: (item: TableRow) => (
                        <React.Fragment>{item.dateModifiedDetails}</React.Fragment>
                    ),
                    // eslint-disable-next-line react/display-name
                    actions: (item: TableRow) => (
                        <React.Fragment>
                            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                                <TableItemAction
                                    tooltipText={"View Historic Audit"}
                                    onClick={() => viewHistoricAudits(item.actions)}
                                    icon={IconType.Eye}
                                />
                                <TableItemAction
                                    tooltipText={"Edit Audit"}
                                    onClick={() => editAudit(item.actions)}
                                    icon={IconType.Edit}
                                    wrapperClassName={"ml-1"}
                                />
                                <DeleteTableItemAction
                                    loading={auditStore.loading}
                                    modalTitle={"Delete Audit"}
                                    modalMessage={`Are you sure you want to delete this audit?`}
                                    onConfirm={async () => {
                                        return deleteAudit(item.actions);
                                    }}
                                    itemName={"Audit"}
                                />
                            </AuthAmI>
                            <AuthAmI accessLevels={[StaffAccessLevel.DutyManager]}>
                                <TableItemAction
                                    tooltipText={"View Audit"}
                                    onClick={() => viewAudit(item.actions)}
                                    icon={IconType.Eye}
                                />
                            </AuthAmI>
                        </React.Fragment>
                    )
                }}
            />
        </React.Fragment>
    );
};

export default AuditListTable;

interface TableRow {
    type: string;
    dateCreatedDetails: JSX.Element[] | JSX.Element;
    dateModifiedDetails: JSX.Element[] | JSX.Element;
    reportingManager: string;
    locationName: string;
    comments: string;
    actions: number;
}

function sortPagedResponseToTableRow(resp: DrugAuditPagedResponse): TableRow[] {
    return resp.results.map((item) => {
        return {
            type: getUiFriendlyText(item.type),
            dateCreatedDetails: getDetailsForDates(item.dateCreated, item.originalAuthor),
            dateModifiedDetails: getDetailsForDates(item.dateModified, item.modifiedAuthor),
            reportingManager: item.reportingManager.staffName,
            locationName: item.locationName,
            comments: item.comments,
            actions: item.auditId
        };
    });
}

function getDetailsForDates(date: number, author: StaffLink) {
    return (
        <React.Fragment>
            <h6 className="mb-0">{author.staffName}</h6>
            <p className="mb-0">{formatUnixToDDMMYYYY(date)}</p>
        </React.Fragment>
    );
}
