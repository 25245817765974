import React from "react";
import {ComponentChildrenProps} from "../../utils/componentUtils";

function TableActionContainer({children}: ComponentChildrenProps) {
    return (
        <React.Fragment>
            <div className="row mt-3 ml-0 mr-0">
                <div className="col mb-4 d-flex justify-content-end pr-0">{children}</div>
            </div>
        </React.Fragment>
    );
}

export default TableActionContainer;
