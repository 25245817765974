import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../../../store/Store";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {nullifyGeneralReportStore} from "../../../../../store/generalReports/actions/GeneralReportActions";
import {nullifyAdministrationReportStore} from "../../../../../store/administrationReports/actions/AdministrationReportsActions";
import AdministrationReportTable from "./Components/AdministrationReportTable";
import TopBar from "../../../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(AdministrationReportTable);

const AdministrationReport = () => {
    const dispatch = useDispatch();

    const administrationReportStore = useSelector(
        (state: RootStore) => state.administrationReports
    );

    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];

        dispatch(setNavigationItems(nav));

        return function () {
            dispatch(nullifyGeneralReportStore());
            dispatch(nullifyAdministrationReportStore());
        };
    }, []);

    return (
        <React.Fragment>
            <TopBar titleText="Administration Reports" />
            <div className="pt-4 page-container">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...administrationReportStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AdministrationReport;
