import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createNewDrugPackCategory} from "../../../store/drugPackCategory/actions/DrugPackCategoryActions";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import EditDrugPackCategoryForm from "./Components/EditDrugPackCategoryForm";
import {RootStore} from "../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(EditDrugPackCategoryForm);

const AddDrugPackCategory = () => {
    const dispatch = useDispatch();

    const drugPackCategoryStore = useSelector((state: RootStore) => state.drugPackCategory);

    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.drugPackCategoryAdd.name,
                path: routeNames.drugPackCategoryAdd.path
            }
        ];

        dispatch(setNavigationItems(nav));
        dispatch(createNewDrugPackCategory());
    }, []);

    return (
        <React.Fragment>
            <TopBar titleText="Add Drug Pack Category" />
            <div className="mt-4 page-container">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...drugPackCategoryStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AddDrugPackCategory;
