import React from "react";
import {useDrugPackCategoryList} from "./Hooks/useDrugPackCategoryList";
import IdNameTable from "../../Table/IdNameTable/IdNameTable";
import {WithServiceState} from "store-fetch-wrappers";
import {routeNames} from "../../Navigation/routeNames";
import {fetchAllDrugPackCategories} from "../../../store/drugPackCategoryList/actions/DrugPackCategoryListActions";
import {deleteDrugPackCategoryById} from "../../../store/drugPackCategory/actions/DrugPackCategoryActions";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(IdNameTable);

function DrugPackCategoryList() {
    const {drugPackCategoryListStore, drugPackCategoryStore} = useDrugPackCategoryList();
    return (
        <React.Fragment>
            <TopBar titleText="Drug Pack Categories" />
            <div className="page-container pt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    {...drugPackCategoryListStore}
                    data={{
                        values: drugPackCategoryListStore.data || [],
                        reduxStore: drugPackCategoryStore,
                        editItemRoute: routeNames.drugPackCategoryEdit.path,
                        newItemRoute: routeNames.drugPackCategoryAdd.path,
                        itemName: "Drug Pack Category",
                        getAll: () => fetchAllDrugPackCategories(),
                        deleteById: (id: number) => deleteDrugPackCategoryById(id)
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default DrugPackCategoryList;
