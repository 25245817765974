import React from "react";
import {AdministrationReport} from "../../../../../../store/administrationReports/actions/AdministrationReportsActionTypes";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import ReportFilters from "../../Components/ReportFilters";
import {GeneralReportType} from "../../../Helpers/reportHelpers";
import FilterContainer from "../../../../../Filters/FilterContainer";
import {toArray} from "../../../../../../utils/sortingUtils";
import AdministrationTableInfo from "./Table/AdministrationTableInfo";
import NoItemsMessage from "../../../../../Table/NoItemsMessage";
import PrintButton from "../../../../../Print/PrintButton";
import PrintAdministrationReport from "../Print/PrintAdministrationReport";
import {useAdministrationReportTable} from "../Hooks/useAdministrationReportTable";

const AdministrationReportTable = (props: AdministrationReport[]) => {
    const {onRequestChanged, backToReports, downloadCsv} = useAdministrationReportTable();
    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <ReportFilters
                    reportType={GeneralReportType.Administration}
                    onRequestChanged={onRequestChanged}
                />
            </FilterContainer>
            <div className="mt-4">
                {toArray(props).length > 0 && (
                    <FormActionContainer>
                        <div className="col pr-0 pl-0 d-flex justify-content-end">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Download CSV"}
                                onClick={() => downloadCsv(toArray(props))}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <PrintButton
                                toPrint={<PrintAdministrationReport {...props} />}
                                printButtonText={"Print Administration Report"}
                                reportHeaderText={"Administration Report"}
                            />
                        </div>
                    </FormActionContainer>
                )}
                {toArray(props).length > 0 ? (
                    <div className="pulse-table-wrapper">
                        <table className="pulse-table fadeIn">
                            <tbody>
                                {toArray(props).map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <AdministrationTableInfo.Header />
                                            <AdministrationTableInfo.Row {...item} />
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <NoItemsMessage
                        message={"There are no administration reports for this time frame. "}
                    />
                )}
            </div>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Back"}
                    onClick={backToReports}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default AdministrationReportTable;
