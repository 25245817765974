import React, {useState} from "react";
import {DisposalReport} from "../../../../../../store/disposalReports/actions/DisposalReportsActionTypes";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";
import {GeneralReportRequest, GrsLocation} from "../../../../../../api/mm";
import {CalendarTypeEnum} from "../../../../../../api/grs";
import {PulseTable} from "pulse_table";
import {toArray} from "../../../../../../utils/sortingUtils";
import NoItemsMessage from "../../../../../Table/NoItemsMessage";
import FilterContainer from "../../../../../Filters/FilterContainer";
import ReportFilters, {ReportFilterProps} from "../../Components/ReportFilters";
import {useDispatch} from "react-redux";
import {getDisposalReport} from "../../../../../../store/disposalReports/actions/DisposalReportsActions";
import {
    GeneralReportType,
    generateReportRequest,
    getQueryStringsForGeneralReportRequest
} from "../../../Helpers/reportHelpers";
import {useHistory} from "react-router-dom";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import {routeNames} from "../../../../../Navigation/routeNames";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import PrintButton from "../../../../../Print/PrintButton";
import PrintDisposalReport from "../Print/PrintDisposalReport";

const DisposalReportTable = (props: DisposalReport[]) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [reportRequest, setReportRequest] = useState<GeneralReportRequest>();

    const onRequestChanged = (filters: ReportFilterProps) => {
        const request = generateReportRequest(
            GeneralReportType.Disposal,
            filters.startTime,
            filters.endTime
        );

        if (!reportRequest) {
            setReportRequest(request);
            fetchData(request);
            return;
        }

        if (JSON.stringify(request) === JSON.stringify(reportRequest)) return;

        setReportRequest(request);
        fetchData(request);
    };

    // Fetches Audits
    const fetchData = (request: GeneralReportRequest | undefined) => {
        if (!request) return;
        const search = getQueryStringsForGeneralReportRequest(request);
        history.push({search});
        dispatch(getDisposalReport(request));
    };

    const backToReports = () => {
        history.replace(routeNames.generalReports.path);
    };

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <ReportFilters
                    reportType={GeneralReportType.Disposal}
                    onRequestChanged={onRequestChanged}
                />
            </FilterContainer>
            <div className="mt-4">
                {toArray(props).length > 0 && (
                    <FormActionContainer>
                        <div className="col pr-0 pl-0 d-flex justify-content-end">
                            <PrintButton
                                toPrint={<PrintDisposalReport {...props} />}
                                printButtonText={"Print Disposal Report"}
                                reportHeaderText={"Disposal Report"}
                            />
                        </div>
                    </FormActionContainer>
                )}
                <PulseTable
                    items={toTableRow(toArray(props))}
                    headers={{
                        drugName: "Drug Name",
                        drugPack: "Drug Pack",
                        batch: "Batch Number",
                        disposalDate: "Disposal Date",
                        expiryDate: "Expiry Date",
                        qty: "Qty",
                        location: "Location",
                        disposedBy: "Disposed By",
                        witnessedBy: "Witnessed By",
                        broken: "Broken",
                        denatured: "Denatured"
                    }}
                    noItemsSection={
                        <NoItemsMessage
                            message={"There are no disposal recorded for this time frame."}
                        />
                    }
                />
            </div>

            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Back"}
                    onClick={backToReports}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default DisposalReportTable;

interface TableRow {
    drugName: string;
    drugPack: string;
    batch: string;
    disposalDate: string;
    expiryDate: string;
    qty: number;
    location: string;
    disposedBy: string;
    witnessedBy: string;
    broken: string;
    denatured: string;
}

function toTableRow(props: DisposalReport[]): TableRow[] {
    return props.map((item) => {
        return {
            drugName: item.drugBatchName,
            drugPack: item.drugPackName,
            batch: item.batchNumber,
            disposalDate: formatUnixToDDMMYYYY(item.expiryDate),
            expiryDate: formatUnixToDDMMYYYY(item.expiryDate),
            qty: item.qty,
            location: getLocationForReport(item.location),
            disposedBy: item.staffMember.staffName,
            witnessedBy: item.witness?.staffName || "N/A",
            broken: item.broken ? "Yes" : "No",
            denatured: item.denatured ? "Yes" : "No"
        };
    });
}

export function getLocationForReport(location: GrsLocation | undefined): string {
    if (!location) return "N/A";

    switch (location.calendarType) {
        case CalendarTypeEnum.Frontline:
            return location.entryName;
        case CalendarTypeEnum.Event:
            return location.venueName;
        default:
            return "N/A";
    }
}
