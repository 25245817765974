import React from "react";
import {DrugExpiryRollout} from "../../../../../../store/expiryReports/actions/ExpiryReportActionTypes";
import {TotalQuantity} from "../../../../../../store/expiryReports/actions/ExpiryReportActions";
import Icon, {IconType} from "../../../../../Icon/Icon";
import Tooltip from "../../../../../Tooltip/Tooltip";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import {useToggle} from "../../../../../Hooks/useToggle";
import ExpiryReportDetails from "./ExpiryReportDetails";

const ExpiryReportRow = (props: DrugExpiryRollout) => {
    const [shown, setShown] = useToggle(false);

    function getHelperText() {
        return `Click 'Show' Button to see all ${props.drugName} batches.`;
    }
    return (
        <React.Fragment>
            <tr className="pulse-table-row">
                <td align="left" className="pulse-table-row-item">
                    {props.drugName}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {!shown && <span>{getHelperText()}</span>}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {!shown && <span>{getHelperText()}</span>}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {!shown && <span>{getHelperText()}</span>}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {getQtyForRollout(props.quantity)}
                </td>
                <td align="left" className="pulse-table-row-item">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={shown ? "Hide" : "Show"}
                        onClick={setShown}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </td>
            </tr>
            {shown && (
                <React.Fragment>
                    {props.drugs.map((item, index) => {
                        return <ExpiryReportDetails {...item} key={index} />;
                    })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ExpiryReportRow;

function getQtyForRollout({
    inDrugPacks,
    disposed,
    inStock,
    administered,
    averageLowStock
}: TotalQuantity): JSX.Element {
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                {getQuantityMarkup(IconType.Syringe, administered, "Total Amount Administered")}
                {getQuantityMarkup(IconType.Disposal, disposed, "Total Amount Disposed")}
                {getQuantityMarkup(IconType.MoveStock, inDrugPacks, "Total Amount In Packs")}
                {getInStockQuantityMarkup(inStock, averageLowStock, "Total Amount In Stock")}
            </div>
        </React.Fragment>
    );
}

function getQuantityMarkup(icon: IconType, amount: number, tooltipText: string): JSX.Element {
    return (
        <React.Fragment>
            <div className="d-inline-block cursor-pointer mr-2">
                <Tooltip tooltipText={tooltipText} place={"left"} theme={"dark"} size={"md"}>
                    <p className="mb-0">
                        {amount}
                        <span className="icon-qty">
                            <Icon rootElement={"Span"} icon={icon} size={"Medium"} />
                        </span>
                    </p>
                </Tooltip>
            </div>
        </React.Fragment>
    );
}

function getInStockQuantityMarkup(
    amount: number,
    lowStockAmount: number,
    tooltipText: string
): JSX.Element {
    function getStockQuantityRAGClass(): string {
        return `${getClassName()} low-stock-sm`;
    }

    function getClassName(): string {
        const lowStockWarningAmount = lowStockAmount + 5;
        if (amount <= lowStockAmount) return "low-stock-red";
        if (amount > lowStockAmount && amount <= lowStockWarningAmount) return "low-stock-yellow";

        return "low-stock-green";
    }
    return (
        <React.Fragment>
            <div className="d-inline-block cursor-pointer mr-2">
                <Tooltip tooltipText={tooltipText} place={"left"} theme={"dark"} size={"md"}>
                    <div className="d-inline-block">
                        <p className="mb-0">{amount}</p>
                    </div>
                    <div className="d-inline-block">
                        <div className={getStockQuantityRAGClass()} />
                    </div>
                </Tooltip>
            </div>
        </React.Fragment>
    );
}
