import React, {useState} from "react";
import {DrugExpiryRollout} from "../../../../../../store/expiryReports/actions/ExpiryReportActionTypes";
import {toArray} from "../../../../../../utils/sortingUtils";
import NoItemsMessage from "../../../../../Table/NoItemsMessage";
import FilterContainer from "../../../../../Filters/FilterContainer";
import ReportFilters, {ReportFilterProps} from "../../Components/ReportFilters";
import {
    GeneralReportType,
    generateReportRequest,
    getQueryStringsForGeneralReportRequest
} from "../../../Helpers/reportHelpers";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {GeneralReportRequest} from "../../../../../../api/mm";
import {routeNames} from "../../../../../Navigation/routeNames";
import {getExpiryReport} from "../../../../../../store/expiryReports/actions/ExpiryReportActions";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import PrintButton from "../../../../../Print/PrintButton";
import PrintExpiryReport from "../Print/PrintExpiryReport";
import ExpiryReportHeader from "./ExpiryReportHeader";
import ExpiryReportRow from "./ExpiryReportRow";

const ExpiryReportTable = (props: DrugExpiryRollout[]) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [reportRequest, setReportRequest] = useState<GeneralReportRequest>();

    const onRequestChanged = (filters: ReportFilterProps) => {
        const request = generateReportRequest(
            GeneralReportType.Expiry,
            filters.startTime,
            filters.endTime
        );

        if (!reportRequest) {
            setReportRequest(request);
            fetchData(request);
            return;
        }

        if (JSON.stringify(request) === JSON.stringify(reportRequest)) return;

        setReportRequest(request);
        fetchData(request);
    };

    // Fetches Audits
    const fetchData = (request: GeneralReportRequest | undefined) => {
        if (!request) return;
        const search = getQueryStringsForGeneralReportRequest(request);
        history.push({search});
        dispatch(getExpiryReport(request));
    };

    const backToReports = () => {
        history.replace(routeNames.generalReports.path);
    };

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <ReportFilters
                    reportType={GeneralReportType.Expiry}
                    onRequestChanged={onRequestChanged}
                />
            </FilterContainer>
            <div className="mt-4">
                {toArray(props).length > 0 && (
                    <FormActionContainer>
                        <div className="col pr-0 pl-0 d-flex justify-content-end">
                            <PrintButton
                                toPrint={<PrintExpiryReport {...props} />}
                                printButtonText={"Print Expiry Report"}
                                reportHeaderText={"Expiry Report"}
                            />
                        </div>
                    </FormActionContainer>
                )}
                {toArray(props).length > 0 ? (
                    <div className="pulse-table-wrapper">
                        <table className={"pulse-table fadeIn"}>
                            <ExpiryReportHeader />
                            <tbody>
                                {toArray(props).map((item, index) => {
                                    return <ExpiryReportRow {...item} key={index} />;
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <NoItemsMessage
                        message={"There are no expiries recorded for this time frame."}
                    />
                )}
            </div>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Back"}
                    onClick={backToReports}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default ExpiryReportTable;
