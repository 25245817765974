import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchDrugPackCategory,
    nullifyDrugPackCategoryStore
} from "../../../store/drugPackCategory/actions/DrugPackCategoryActions";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import EditDrugPackCategoryForm from "./Components/EditDrugPackCategoryForm";
import {RootStore} from "../../../store/Store";
import {useParams} from "react-router-dom";
import {WithServiceState} from "store-fetch-wrappers";
import {usePageUrl} from "../../Hooks/usePageUrl";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(EditDrugPackCategoryForm);

const EditDrugPackCategory = () => {
    const dispatch = useDispatch();
    const params: any = useParams();
    const drugPackCategoryStore = useSelector((state: RootStore) => state.drugPackCategory);
    const {fullPath} = usePageUrl();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.drugPackCategoryEdit.name,
                path: fullPath
            }
        ];

        dispatch(setNavigationItems(nav));
        dispatch(fetchDrugPackCategory(+params.id));

        return async function () {
            dispatch(nullifyDrugPackCategoryStore());
        };
    }, []);

    return (
        <React.Fragment>
            <TopBar titleText="Edit Drug Pack Category" />
            <div className="mt-4 page-container">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...drugPackCategoryStore}
                />
            </div>
        </React.Fragment>
    );
};

export default EditDrugPackCategory;
