import {ComponentChildrenProps} from "../../utils/componentUtils";
import React from "react";

const FormActionContainer = ({
    children,
    withColumn,
    wrapperClassName
}: FormActionContainerProps) => {
    return (
        <div className="row-modifier">
            <div className={`${wrapperClassName} mc-form-row`}>
                <div className="row mt-3">
                    {withColumn ? (
                        <div className="col">{children}</div>
                    ) : (
                        <React.Fragment>{children}</React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormActionContainer;

interface FormActionContainerProps extends ComponentChildrenProps {
    withColumn?: boolean;
    wrapperClassName?: string;
}
