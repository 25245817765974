import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {
    fetchDrugBatchById,
    nullifyDrugBatchStore
} from "../../../store/drugBatch/actions/DrugBatchActions";
import {RootStore} from "../../../store/Store";
import StockManagementAuditTable from "./Components/StockManagementAuditTable";
import {WithServiceState} from "store-fetch-wrappers";
import {fetchAllDrugPacks} from "../../../store/drugPackList/actions/DrugPackListActions";
import {getAllStaffMembers} from "../../../store/staffList/actions/StaffListActions";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(StockManagementAuditTable);

const StockManagementAudit = () => {
    const dispatch = useDispatch();
    const params: any = useParams();
    const drugBatchStore = useSelector((state: RootStore) => state.drugBatch);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            }
        ];

        dispatch(setNavigationItems(navItems));
        dispatch(fetchDrugBatchById(+params.id));
        dispatch(fetchAllDrugPacks());
        dispatch(getAllStaffMembers());

        return async function () {
            dispatch(nullifyDrugBatchStore());
        };
    }, []);

    return (
        <>
            <TopBar titleText="Stock Management Audit" />
            <div className="page-container mt-4">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...drugBatchStore}
                />
            </div>
        </>
    );
};

export default StockManagementAudit;
